import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {Row, Col, Spinner, CardTitle, Form, Label} from "reactstrap"
import {withRouter} from "react-router"
import {ToastContainer, toast, Slide} from "react-toastify"
import {Link} from "react-router-dom"
import Select from "react-select";

const DataTableSchedulesGrp = props => {
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [annee, setAnnee] = useState("");
    const [imp, setImp] = useState(0);
    const [view, setView] = useState(0);
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        sizePerPage: 50,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    const [departement, setDepartement] = useState([]);
    const [selectDepartement, setSelectDepartement] = useState("");
    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "group_cour",
            text: "Group Cour",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "group_td",
            text: "Group TD",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "id",
            isDummyField: true,
            text: "Emploi 1 ère Semestre",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                view === 1 ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Link to={"/EmploiGroup?id=" + row.id + "/" + props.semestre}>
                            <i
                                style={{
                                    color: "green",
                                    cursor: "pointer",
                                }}
                                className="fas fa-eye"
                            ></i>
                        </Link>
                    </div>
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#a7a3a3", cursor: "pointer"}}
                            className="fas fa-eye"
                        ></i>
                    </div>
                )
            ),
        },
        {
            dataField: "id",
            isDummyField: true,
            text: "Téléchargement",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                imp === 1 ? (
                    <a
                        href={
                            "https://flshs.scolarite.backcresus-institut.ovh/api/impression_group_shedules/" +
                            row.id + "/" + props.semestre + "/" + annee
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{display: "flex", justifyContent: "center"}}
                    >
                        <i
                            style={{color: "#955cd5", cursor: "pointer"}}
                            // onClick={""}
                            className="fas fa-file-download"
                        ></i>
                    </a>
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#a7a3a3", cursor: "pointer"}}
                            className="fas fa-file-download"
                        ></i>
                    </div>
                )
            ),
        },
    ]

    const {SearchBar} = Search
    //
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    useEffect(async () => {
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Emploi groupes")
        );
        setImp(typeArticleSubMenu.sous_menu[0].imp)
        setView(typeArticleSubMenu.sous_menu[0].view)

        var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
        var annee_id = userAuthScolarite.user.annee_id
        setAnnee(annee_id)

        const resDepartement = await API.get("departement/select").then(resDepartement => {
            setDepartement(resDepartement.data.Departement)
            setSelectDepartement("")
        })
        const res = await API.post("emploi/group/list", {
            semestre_id: props.semestre,
            annee_id: annee_id
        }).then(res => {
            setOrders(res.data.groups)
        })
        setLoading(true)
    }, [props.semestre])
    const search = async () => {
        if (selectDepartement.value != "" || selectDepartement.value != null) {
            setOrders([])
            const res = await API.post("group/get_by_dep", {
                semestre_id: props.semestre,
                departement_id: selectDepartement.value,
                annee_id
            }).then(res => {
                setOrders(res.data.groups)
            })
        } else {
            toast.error("⛔ département est obligatroire !", {
                containerId: "A",
            })
        }
    }
    const searchAll = async () => {
        var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
        var annee_id = userAuthScolarite.user.annee_id
        const res = await API.post("emploi/group/list", {
            semestre_id: props.semestre,
            annee_id: annee_id
        }).then(res => {
            setOrders(res.data.groups)
            setLoading(true)
        })
    }
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row className="mt-4">
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <div>
                                            <CardTitle style={{color: "#556ee6"}} className="h4">
                                                Critéres de Recherches
                                            </CardTitle>
                                            <Form className="mt-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-lastname-input2">Département :</Label>
                                                            <Select
                                                                options={departement}
                                                                isSearchable={true}
                                                                // isClearable={true}
                                                                value={selectDepartement}
                                                                onChange={e => setSelectDepartement(e)}
                                                            />
                                                        </div>
                                                    </Col>


                                                    <Col lg="6" style={{padding: "26px 35px"}}>
                                                        <button
                                                            type="button"
                                                            style={{
                                                                backgroundColor: "#761C19",
                                                                borderColor: "#761C19",
                                                            }}
                                                            className="btn btn-success mb-2 me-2"
                                                            onClick={search}
                                                        >
                                                            Rechercher
                                                        </button>
                                                        <button
                                                            type="button"
                                                            style={{
                                                                backgroundColor: "#761C19",
                                                                borderColor: "#761C19",
                                                            }}
                                                            className="btn btn-success mb-2 me-2"
                                                            onClick={searchAll}
                                                        >
                                                            Afficher tout
                                                        </button>

                                                    </Col>

                                                </Row>
                                            </Form>
                                            <div className="mb-3 row" style={{display: "flex"}}>
                                                <div className="col-md-6 mb-3 row">
                                                    <div className="col-md-8 search-box-etd">
                                                        <div
                                                            className="search-box me-2 mb-2 d-inline-block"
                                                            style={{width: "100%"}}
                                                        >
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {imp === 1 ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                        }}
                                                        className="col-md-6 mb-3 row"
                                                    >
                                                        <div
                                                            style={{textAlign: "right"}}
                                                            className="col-md-6 select-action"
                                                        >
                                                            <a
                                                                href={"https://flshs.scolarite.backcresus-institut.ovh/api/impression_group_shedules_dep" + "/" + props.semestre + "/" + annee}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <button className="btn btn-primary btn btn-primary">
                                                                    <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                                                    Emplois groupes
                                                                </button>
                                                            </a>
                                                        </div>
                                                        {(selectDepartement.value != undefined) ? (
                                                        <a className="col-md-6"
                                                           href={"https://flshs.scolarite.backcresus-institut.ovh/api/impression_group_shedules_dep" + "/" + selectDepartement.value + "/" + props.semestre + "/" + annee}
                                                           target="_blank"
                                                           rel="noreferrer"
                                                        >
                                                            <button
                                                                className="btn btn-primary btn btn-primary">
                                                                <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                                                Emplois groupes par département
                                                            </button>
                                                        </a>
                                                        ) : (null)}

                                                    </div>
                                                ) : (null)}
                                            </div>

                                        </div>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...{" "}
                        </h4>
                    </div>
                )}
            </div>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withRouter(DataTableSchedulesGrp)
DataTableSchedulesGrp.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.any,
    semestre: PropTypes.any,
}
